
export class ExampleGenerator {
  static generateScoreValue (field) {
    const score = Math.random() * 5.0
    field.setText(score.toFixed(2))
    field.setText('')
    field.setIcon('score')
    if ((score >= 0.0) && (score <= 0.25)) {
      field.setValue(0)
      field.setFileValue(0)
    }
    if ((score > 0.25) && (score <= 0.75)) {
      field.setValue(0.5)
      field.setFileValue(0.5)
    }
    if ((score > 0.75) && (score <= 1.25)) {
      field.setValue(1.0)
      field.setFileValue(1.0)
    }
    if ((score > 1.25) && (score <= 1.75)) {
      field.setValue(1.5)
      field.setFileValue(1.5)
    }
    if ((score > 1.75) && (score <= 2.25)) {
      field.setValue(2)
      field.setFileValue(2.0)
    }
    if ((score > 2.25) && (score <= 2.75)) {
      field.setValue(2.5)
      field.setFileValue(2.5)
    }
    if ((score > 2.75) && (score <= 3.25)) {
      field.setValue(3)
      field.setFileValue(3)
    }
    if ((score > 3.25) && (score <= 3.75)) {
      field.setValue(3.5)
      field.setFileValue(3.5)
    }
    if ((score > 3.75) && (score <= 4.25)) {
      field.setValue(4)
      field.setFileValue(4)
    }
    if ((score > 4.25) && (score <= 4.75)) {
      field.setValue(4.5)
      field.setFileValue(4.5)
    }
    if ((score > 4.75) && (score < 5.0)) {
      field.setValue(5)
      field.setFileValue(5)
    }
  }

  static generatePValues (field) {
    field.setValue(Math.random())
  }

  static generateUniprotLink (field) {
    const fieldValue = 'https://www.uniprot.org/uniprot/P01308'

    // const firstColon = fieldValue.indexOf(':')
    // const secondColon = firstColon + fieldValue.substring(firstColon + 1).indexOf(':') + 1
    // const iconName = fieldValue.substring(0, fieldValue.indexOf(':'))
    // const hRefText = fieldValue.substring(secondColon + 1)
    // const displayText = fieldValue.substring(firstColon + 1, secondColon)

    field.setValue(fieldValue)
    field.setText('')
    field.setIcon('uniprot.svg')
    field.setLink(fieldValue)
  }
}
