import { render, staticRenderFns } from "./LoginPrompt.vue?vue&type=template&id=523904be&scoped=true&"
import script from "./LoginPrompt.vue?vue&type=script&lang=js&"
export * from "./LoginPrompt.vue?vue&type=script&lang=js&"
import style0 from "./LoginPrompt.vue?vue&type=style&index=0&id=523904be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523904be",
  null
  
)

export default component.exports