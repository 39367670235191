/**
 * The Filter Vuex store.
 *
 * Documentation pending :-)
 *
 */

import Vue from 'vue'
import { DataColumn } from './DataColumn'

function integerFilter (data, operation, value) {
  switch (operation) {
    case 'eq': {
      return data === value
    }
    case 'lt': {
      return data < value
    }
    case 'gt': {
      return data > value
    }
    // Throw an error here?
    default: {
      return true
    }
  }
}

function textFilter (data, operation, value) {
  switch (operation) {
    case 'eq': {
      return data.toLowerCase() === value.toLowerCase()
    }
    case 'neq': {
      return data.toLowerCase() !== value.toLowerCase()
    }
    case 'contains': {
      return data.toLowerCase().includes(value.toLowerCase())
    }
    // Throw an error here?
    default: {
      return true
    }
  }
}

function booleanFilter (data, operation, value) {
  switch (operation) {
    case 'eq': {
      if (typeof value === 'boolean') {
        if (data === DataColumn.naSymbol) {
          return false
        } else {
          return data === value
        }
      } else {
        const valueString = value.toLowerCase()
        if (data === DataColumn.naSymbol) {
          return false
        } else if (valueString === 'y' || valueString === '1' || valueString[0] === 't') {
          return data
        } else {
          return !data
        }
      }
    }
    // Throw an error here?
    default: {
      return true
    }
  }
}

function evaluationSingleFilter (node, filter) {
  if (filter.type === 'RealDataField') {
    let value
    if (node.data[filter.column].Attributes === undefined) {
      value = node.data[filter.column]
    } else {
      value = node.data[filter.column].Attributes.value
    }
    return integerFilter(
      value,
      filter.operator,
      Number(filter.value)
    )
  } else if (filter.type === 'IntegerDataField') {
    return integerFilter(
      node.data[filter.column].Attributes.value,
      filter.operator,
      Number(filter.value)
    )
  } else if (filter.type === 'TextDataField') {
    return textFilter(
      node.data[filter.column].Attributes.value,
      filter.operator,
      filter.value
    )
  } else if (filter.type === 'LinkTextDataField') {
    return textFilter(
      node.data[filter.column].Attributes.value,
      filter.operator,
      filter.value
    )
  } else if (filter.type === 'Favorites') {
    return booleanFilter(
      node.data.favorite,
      filter.operator,
      filter.value
    )
  } else if (filter.type === 'BooleanDataField') {
    return booleanFilter(
      node.data[filter.column].Attributes.value,
      filter.operator,
      filter.value
    )
    // Remember - Real data has no DataField object with Attributes but just the raw value and Boolean have no cellrendererFramework
  } else if (!(node.data[filter.column]) || !node.data[filter.column].Column) {
    return integerFilter(
      node.data[filter.column],
      filter.operator,
      Number(filter.value)
    )
  } else if (node.data[filter.column].Column.Type === DataColumn.BooleanType) {
    return booleanFilter(
      node.data[filter.column].Attributes.value,
      filter.operator,
      filter.value
    )
  } else {
    return integerFilter(
      node.data[filter.column],
      filter.operator,
      Number(filter.value)
    )
  }
}

function evaluateLogic (booleanList, operatorList) {
  let result = true

  if (booleanList.length === 0) { // If there are no filter lines then return true
    return result
  }

  const ORList = [] // Holds all evaluations to be or'ed together (single or results of AND's)
  booleanList.forEach((booleanValue, index) => {
    if (index > 0) {
      const operator = operatorList[index - 1]
      if (operator === 'AND') {
        ORList.push(ORList.pop() && booleanValue)
      } else {
        ORList.push(booleanValue)
      }
    } else {
      ORList.push(booleanValue) // put the first bool directly to the OR evaluation list
    }
  })
  result = false
  let orIndex = 0
  // Run through all value to be OR'ed together. If any of them is true then the whole
  // evaluation is true and we can stop evaluation
  while (orIndex < ORList.length && !result) { // stop iterating if a result is true
    result = ORList[orIndex]
    orIndex++
  }
  return result
}

export const state = () => ({
  filterGroups: [],
  filterModalActive: false,
  filterEditActive: false,
  showFavorites: false,
  importedData: false,
  rowData: [],
  geneListName: '',
  geneListFilterSet: '',
  geneListColumn: ''
})

function guessGeneListColumn (geneIds) {
  if (!geneIds) {
    return undefined
  } else if (geneIds.every(x => x.includes('_'))) {
    return 'UniProtID'
  } else if (geneIds.every(x => ([6, 10].includes(x.length)))) {
    return 'UniProtAC'
  } else {
    return 'PrimaryGene'
  }
}

export const getters = {
  hasGeneListFilter (state) {
    return state.geneListFilterSet !== ''
  },
  geneListColumnPretty (state) {
    const names = {
      UniProtID: 'UniProt ID',
      UniProtAC: 'UniProt Accession',
      PrimaryGene: 'Primary Gene Name'
    }
    return names[state.geneListColumn]
  },
  allGeneListIds (state) {
    return state.rowData.map((row) => {
      return row[state.geneListColumn].Attributes.value
    })
  },
  geneListUnmappedIds (state, getters) {
    if (!state.geneListFilterSet) {
      return []
    }
    return state.geneListFilterSet.filter((geneId) => {
      return !getters.allGeneListIds.includes(geneId)
    })
  },
  isFavoritesActive (state) {
    return state.showFavorites
  },
  isFilterModalActive (state) {
    return state.filterModalActive
  },
  isFilterEditActive (state) {
    return state.filterEditActive
  },
  filteredColumnIds (state) {
    return state.filterGroups.map((filterGroup) => {
      return filterGroup.filters.map((filter) => {
        return filter.column
      })
    }).flat()
  },
  externalFilter (state) {
    // ag-Grid expects a function, so return one which can be called to run the
    // filter.
    return function (node) {
      const filterGroupResults = []
      const groupOperators = []
      // Evaluate each group filter and push the group result to a list to evaluate afterwards
      // Push the group evaluation and the group operator to combine with the previous group
      state.filterGroups.forEach((group) => {
        const singleResults = []
        group.filters.forEach((filter) => {
          singleResults.push(evaluationSingleFilter(node, filter))
        })
        // Collect the results of all single group evaluations into a list
        filterGroupResults.push(evaluateLogic(singleResults, group.operators))
        groupOperators.push(group.groupOperator) // build the logic operator list for grouping logic
      })
      const favoriteAndFilterResult = evaluateLogic(filterGroupResults, groupOperators)

      // Check whether the row should be hidden due to the "Favorites" filter.
      const filteredOutFavourite = state.showFavorites && booleanFilter(node.data.favorite, 'eq', false)
      if (state.geneListFilterSet === '') {
        return favoriteAndFilterResult && !filteredOutFavourite
      } else {
        const inGeneList = state.geneListFilterSet.has(node.data[state.geneListColumn].Attributes.value)
        return favoriteAndFilterResult && inGeneList && !filteredOutFavourite
      }
    }
  },

  isFilterPresent (state) {
    // ag-Grid expects a function, so return one which can be called to check
    // if a filter exists
    return function () {
      return state.filterGroups.length > 0 || state.showFavorites || state.geneListFilterSet !== ''
    }
  }
}

export const actions = {
  setGeneListFilter ({ commit }, { geneList, rowData, geneListName }) {
    return new Promise((resolve) => {
      const geneListCleaned = geneList.filter((v) => { return v.length > 0 })
      const geneListColumn = guessGeneListColumn(geneListCleaned)
      commit('setGeneListFilterSet', geneListCleaned)
      commit('setRowData', rowData)
      commit('setGeneListName', geneListName)
      commit('setGeneListColumn', geneListColumn)
      resolve()
    })
  },
  saveColumnStates ({ state }, columnStates) {
    const data = JSON.stringify(columnStates)
    const blob = new Blob([data], { type: 'text/plain' })
    const e = document.createEvent('MouseEvents')
    const a = document.createElement('a')
    a.download = 'columns.json'
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initEvent('click', true, false)
    a.dispatchEvent(e)
  },
  saveFilters ({ state }) {
    const data = JSON.stringify(state.filterGroups)
    const blob = new Blob([data], { type: 'text/plain' })
    const e = document.createEvent('MouseEvents')
    const a = document.createElement('a')
    a.download = 'filter.json'
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initEvent('click', true, false)
    a.dispatchEvent(e)
  }
}

export const mutations = {
  setGeneListFilterSet (state, geneList) {
    state.geneListFilterSet = new Set(geneList)
  },
  setRowData (state, rowData) {
    state.rowData = rowData
  },
  setGeneListColumn (state, geneListColumn) {
    state.geneListColumn = geneListColumn
  },
  setGeneListName (state, geneListName) {
    state.geneListName = geneListName
  },
  removeGeneListFilter (state) {
    state.geneListFilterSet = ''
    state.geneListColumn = ''
    state.geneListName = ''
    state.geneListUnmappedIds = []
  },
  loadFilters (state, filters) {
    state.filterGroups = filters
  },
  closeFilterModal (state) {
    state.filterModalActive = false
  },
  openFilterModal (state) {
    state.filterModalActive = true
  },
  closeFilterEditModal (state) {
    state.filterEditActive = false
  },
  openFilterEditModal (state) {
    state.filterEditActive = true
  },
  setFilterGroups (state, groups) {
    state.filterGroups = groups
  },
  addFilterGroup (state, group) {
    const newIndex = state.filterGroups.length
    Vue.set(state.filterGroups, newIndex, group)
  },
  resetFilters (state, group) {
    state.showFavorites = false
    state.filterGroups = []
    state.geneListFilterSet = ''
    state.geneListColumn = ''
  },
  updateFilterGroup (state, group) {
    const index = state.filterGroups.findIndex(storeGroup => storeGroup.id === group.id)
    Vue.set(state.filterGroups, index, group)
  },
  updateGroupOperator (state, { index, operator }) {
    state.filterGroups[index].groupOperator = operator
  },
  triggerGridFilterOn (state) {
    state.triggerGridFilter = true
  },
  showFavorites (state) {
    state.showFavorites = true
  },
  hideFavorites (state) {
    state.showFavorites = false
  },
  triggerGridFilterOff (state) {
    state.triggerGridFilter = false
  },
  removeFilterGroup (state, id) {
    const index = state.filterGroups.findIndex(storeGroup => storeGroup.id === id)
    state.filterGroups.splice(index, 1)
  }
}
