//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import axios from 'axios'

export default {
  data () {
    return {
      password1: '',
      password2: '',
      token: '',
      passwordResetSuccess: '',
      passwordResetError: ''
    }
  },
  computed: {
    showCloseButton () {
      if (this.passwordResetSuccess) {
        return true
      }
      if (this.passwordResetError) {
        return true
      }
      return false
    }
  },
  created () {
    this.token = this.$route.query.reset_password
  },
  methods: {
    resetClick () {
      this.sendPassword()
    },
    sendPassword () {
      const data = { token: this.token, password: this.password1 }
      axios.post(this.$config.backendURL + '/reset-password', data).then(() => {
        this.passwordResetSuccess = 'Password successfully reset'
        this.$emit('passwordResetSuccess')
      }).catch(() => {
        this.passwordResetError = 'Something has gone wrong, please contact support for assistance.'
      })
    },
    cancelClick () {
      this.$emit('passwordResetClosed')
    },
    closeClick () {
      this.$emit('passwordResetClosed')
    }
  }
}
