export const state = () => ({
  columnDefs: [],
  columnState: []
})

export const mutations = {
  setColumnDefs (state, columnDefs) {
    state.columnDefs = columnDefs
  },
  setColumnState (state, columnState) {
    state.columnState = columnState
  }
}
