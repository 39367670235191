import axios from 'axios'

// import $store from '~/store' import if needed ...

// ******  EXTERNAL FUNCTIONS FOR THE DATASET ******
export const getters = {
  addAuthenticationInfo: state => (data) => {
    data.token = state.Token
    data.user = state.UserName
    return data
  },
  getUserName: (state) => {
    return state.UserName
  },
  getToken: (state) => {
    return state.Token
  },
  isAuthenticated: (state) => {
    return (state.UserName && state.Token)
  }
}

export const state = () => ({
  Token: '',
  UserName: ''
})

export const mutations = {
  setToken (state, token) {
    state.Token = token
  },
  setUserName (state, username) {
    state.UserName = username
  }
}

export const actions = {
  login ({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(data.config.backendURL + '/login', { login: data.userName, password: data.userPassword })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.token && response.data.token.length > 1) {
              commit('setToken', response.data.token)
              commit('setUserName', response.data.user)
            }
            resolve(response)
          } else {
            reject(new Error('Login failed'))
          }
        }).catch((responseError) => {
          reject(new Error('Login failed ' + responseError))
        })
    }).catch((error) => {
      console.log('error : ' + error)
    })
  }
}
