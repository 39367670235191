/* eslint-disable no-unused-vars */
import axios from 'axios'
import { openDB, deleteDB } from 'idb'

const dbVersion = 2
const dbName = 'targetdata'
const dbStore = 'dataspec'

async function setupDb () {
  await openDB(dbName, dbVersion, {
    upgrade (db) {
      db.createObjectStore(dbStore)
    }
  })
}

async function storeInDb (rawData, dataSha, dataKey) {
  const db = await openDB(dbName, dbVersion)
  const encryptor = require('simple-encryptor')(dataKey)
  rawData.data = encryptor.encrypt(rawData.data)
  rawData.specification = encryptor.encrypt(rawData.specification)
  db.add(dbStore, rawData, dataSha)
  const data = await db.get(dbStore, dataSha)
  db.close()
  return data
}

async function getFromDb ({ dataSha, dataKey }) {
  const db = await openDB(dbName, dbVersion)
  const data = await db.get(dbStore, dataSha)
  db.close()
  if (data !== undefined) {
    const encryptor = require('simple-encryptor')(dataKey)
    data.data = encryptor.decrypt(data.data)
    data.specification = encryptor.decrypt(data.specification)
  }
  return data
}

function getDataAndSpecFromServer (data) {
  return new Promise((resolve, reject) => {
    const request = {
      url: data.config.backendURL + '/datasource',
      data: data.authenticationInfo
    }
    axios
      .post(request.url, request.data)
      .then((response) => {
        resolve(response)
      }).catch((requestError) => {
        reject(requestError)
      })
  }).catch((error) => {
    throw (error)
  })
}

function getDataShaFromServer (data) {
  return new Promise((resolve, reject) => {
    const request = {
      url: data.config.backendURL + '/datasha',
      data: data.authenticationInfo
    }
    axios
      .post(request.url, request.data)
      .then((response) => {
        resolve(response)
      }).catch((requestError) => {
        reject(requestError)
      })
  }).catch((error) => {
    throw (error)
  })
}

export const actions = {
  fetchSpecAndDataFromDb ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      setupDb().then(() => {
        getDataShaFromServer(data).then((shaResponse) => {
          const data = {
            dataSha: shaResponse.data.result.dataSha,
            dataKey: shaResponse.data.result.dataKey
          }
          getFromDb(data).then((response) => {
            resolve(response)
          })
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchSpecAndDataFromServer ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      getDataAndSpecFromServer(data).then((dataResponse) => {
        getDataShaFromServer(data).then((shaResponse) => {
          storeInDb(dataResponse.data.result, shaResponse.data.result.dataSha, shaResponse.data.result.dataKey).then((response) => {
            resolve(response)
          })
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  dropDb () {
    return new Promise((resolve, reject) => {
      deleteDB(dbName).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
