export function formatToXDigitPrecision (numberToFormat, digits) {
  if (isNaN(numberToFormat)) { // Not a number ... nothing we can do
    return numberToFormat
  }

  numberToFormat = Number(numberToFormat)
  if ((numberToFormat === 0.0) ||
      (numberToFormat >= Math.pow(10, -(digits - 1)) && numberToFormat < Math.pow(10, digits - 1)) ||
      (numberToFormat <= -Math.pow(10, -(digits - 1)) && numberToFormat > -Math.pow(10, digits - 1))) {
    return numberToFormat.toFixed(digits)
  }

  return numberToFormat.toExponential(digits - 1)
}

export function formatToFixed (numberToFormat, digits) {
  if (isNaN(numberToFormat)) { // Not a number ... nothing we can do
    return numberToFormat
  }
  numberToFormat = Number(numberToFormat)
  return numberToFormat.toFixed(digits)
}

export function formatToScientific (numberToFormat, digits) {
  if (isNaN(numberToFormat)) { // Not a number ... nothing we can do
    return numberToFormat
  }
  numberToFormat = Number(numberToFormat)
  return numberToFormat.toExponential(digits)
}
