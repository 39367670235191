//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      form_username: '',
      form_userpassword: '',
      loginError: ''
    }
  },
  methods: {
    clearAuthentication () {
      this.$store.commit('Authentication/setToken', '')
      this.$store.commit('Authentication/setUserName', '')
      if (this.$cookies !== undefined) {
        this.$cookies.remove('Token')
        this.$cookies.remove('UserName')
      }
    },
    storeAuthentication (token, userName) {
      this.$store.commit('Authentication/setToken', token)
      this.$store.commit('Authentication/setUserName', userName)
      this.$cookies.set(
        'Token',
        this.$store.getters['Authentication/getToken'],
        null,
        null,
        this.$config.cookieDomain
      )
      this.$cookies.set(
        'UserName',
        this.$store.getters['Authentication/getUserName'],
        null,
        null,
        this.$config.cookieDomain
      )
    },
    resetPasswordClick () {
      this.$emit('showPasswordResetRequestPopUp')
    },
    loginClick () {
      const data = { config: this.$config, userName: this.form_username, userPassword: this.form_userpassword }
      this.$store.dispatch('Authentication/login', data)
        .then((response) => {
          // console.log('Got login response')
          this.storeAuthentication(response.data.token, response.data.user)
          this.$emit('loginSuccessfull')
        }).catch((error) => {
          console.log('Login failed - Clearing authentication')
          this.loginError = 'Login failed!'
          this.clearAuthentication()
          console.log(error)
        })
    },
    cancelClick () {
      this.$emit('loginCancelled')
    }

  }
}
