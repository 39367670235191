const separator = ','

function parseBuildHeader (columnDefs) {
  let firstRow = ''
  let secondRow = ''
  let thirdRow = ''
  const columnids = []
  columnDefs.forEach((header) => {
    firstRow += header.headerName
    header.children.forEach((subHeader) => {
      if (subHeader.children === undefined) {
        // Not actually a subHeader, but a column without a subHeader
        firstRow += separator
        secondRow += separator
        thirdRow += '"' + subHeader.headerName + '"'
        thirdRow += separator
        columnids.push(subHeader.field)
      } else {
        secondRow += '"' + subHeader.headerName + '"'
        subHeader.children.forEach((column) => {
          firstRow += separator
          secondRow += separator
          thirdRow += '"' + column.headerName + '"'
          thirdRow += separator
          columnids.push(column.field)
        })
      }
    })
  })
  const data = {
    header: `${firstRow}\n${secondRow}\n${thirdRow}\n`,
    columnids
  }
  return data
}

function filterRawData (rawData, ids) {
  const filteredRawData = Object.keys(rawData)
    .filter(key => ids.includes(key))
    .reduce((obj, key) => {
      obj[key] = rawData[key]
      return obj
    }, {})
  return filteredRawData
}

function parseBuildRows (rawData, columnids) {
  let exportData = ''
  Object.keys(rawData).forEach((id) => {
    const rowArray = []
    const row = rawData[id]
    columnids.forEach((columnId) => {
      rowArray.push('"' + row[columnId] + '"')
    })
    exportData += rowArray.join(separator)
    exportData += '\n'
  })
  return exportData
}

export const actions = {
  buildExportCsv (_, { columnDefs, rawData, ids }) {
    // Parsing happens here
    const filteredRawData = filterRawData(rawData, ids)
    const parsedHeader = parseBuildHeader(columnDefs)
    const headerString = parsedHeader.header
    const columnids = parsedHeader.columnids
    const dataString = parseBuildRows(filteredRawData, columnids)
    const blob = new Blob([headerString + dataString], { type: 'text/plain' })
    const e = document.createEvent('MouseEvents')
    const a = document.createElement('a')
    a.download = 'export.csv'
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':')
    e.initEvent('click', true, false)
    a.dispatchEvent(e)
  }
}
