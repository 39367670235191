/**
 * The Template handler takes care of replacing placeholder text in the value of
 * all fields in the dataset. If a value form the data source contains
 * {{Protein_info.Uniprot_AC}} then in stead of the placeholder the value of the
 * field "Protein_info.Uniprot_AC" will be inserted in its place. So it is possible
 * to build a link or an icon image reference dynamically. Like stating a field value in the
 * data source to be "http://www.uniprot.com?protien={{Protein_info.Uniprot_AC}} or
 * image "proteintype{{Protein_info.Type}}.png"
 */

import { DataColumn } from './DataColumn.js'
import { DataField } from './DataField.js'

export class TemplateHandler {
  static cachedReferencedColumns = {} // All values in a column must reference the same column

  static substitutePlaceholders (columnsList) {
    columnsList.forEach((column) => {
      if (column.Type !== DataColumn.RealType) {
        const targets = Object.keys(column.DataMap)
        targets.forEach((targetID) => {
          if (column.Type === DataColumn.TextType || column.Type === DataColumn.LinkType) {
            const currentValue = column.DataMap[targetID].Attributes.value
            if (currentValue && currentValue.includes('{{')) { // This field contains a placeholder to be substituted
              column.DataMap[targetID].Attributes.value = TemplateHandler.replaceText(currentValue, targetID, columnsList)
            }
          }
          const currentLink = column.DataMap[targetID].Attributes.link
          if (currentLink && currentLink.includes('{{')) { // This field contains a placeholder to be substituted
            column.DataMap[targetID].Attributes.link = TemplateHandler.replaceText(currentLink, targetID, columnsList)
          }
          const currentIcon = column.DataMap[targetID].Attributes.icon
          if (currentIcon && currentIcon.includes('{{')) { // This field contains a placeholder to be substituted
            column.DataMap[targetID].Attributes.icon = TemplateHandler.replaceText(currentIcon, targetID, columnsList)
          }
          const currentLabel = column.DataMap[targetID].Attributes.label
          if (currentLabel && currentLabel.includes('{{')) { // This field contains a placeholder to be substituted
            column.DataMap[targetID].Attributes.label = TemplateHandler.replaceText(currentLabel, targetID, columnsList)
          }
        })
      }
    })
  }

  static replaceText (value, targetID, columnList) {
    try {
      let originalPlaceholderText = value.substring(value.indexOf('{{'))
      originalPlaceholderText = originalPlaceholderText.substring(0, originalPlaceholderText.indexOf('}}') + 2)
      const replacedText = originalPlaceholderText.substring(2, originalPlaceholderText.indexOf('}}'))

      // Cache the search for the column referenced. When a placeholder refernce column has been found - save it for fast lookup
      if (TemplateHandler.cachedReferencedColumns[replacedText.trim()] === undefined) {
        TemplateHandler.cachedReferencedColumns[replacedText.trim()] = columnList.find(columnElement =>
          replacedText.trim() === columnElement.FieldName)
      }
      const referencedColumn = TemplateHandler.cachedReferencedColumns[replacedText.trim()]
      const newText = referencedColumn.DataMap[targetID].Attributes[DataField.Value]
      if (newText === DataColumn.naSymbol) {
        return 'NA'
      }
      return value.replace(originalPlaceholderText, newText)
    } catch (error) {
      console.error('Error replacing ' + value)
      console.error(error)
    }
  }
}
