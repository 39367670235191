export const state = () => ({
  loadingSpinnerActive: false,
  loadingSpinnerText: ''
})

export const mutations = {
  showLoadingSpinner (state) {
    state.loadingSpinnerActive = true
  },
  hideLoadingSpinner (state) {
    state.loadingSpinnerActive = false
  },
  setLoadinSpinnerText (state, text) {
    state.loadingSpinnerText = text
  }
}

export const actions = {
  startLoadingSpinner ({ commit }, text) {
    commit('setLoadinSpinnerText', text)
    commit('showLoadingSpinner')
  },
  stopLoadingSpinner ({ commit }) {
    commit('setLoadinSpinnerText', '')
    commit('hideLoadingSpinner')
  }
}
