/**
 * The DataSet is the data container for all target data used in the target portal. It serves to load the data intially,
 * to give the datastructure needed for the data table component (ag-grid)and to handle update of the data to be shown in
 * in the datatable by add/removing column of data.
 * -
 * @columnList
 * The central structure is the list of data columns "columnList". Each DataColumn holds a column definition and the column data
 * for all targets. See DataColumn.
 * The structure is as follows.
 * -
 * DataColumn A           DataColumn B            DataColumn C            DataColumn D
 *   |                      |                       |                       |
 * [  {targetA: valueAA},  [  {targetA: valueBA},   [  {targetA: valueCA},   [  {targetA: valueDA},
 *    {targetB: valueAB},     {targetB: valueBB},      {targetB: valueCB},      {targetB: valueDB},
 *    {targetC: valueAC},     {targetC: valueBC},      {targetC: valueCC},      {targetC: valueDC},
 *    .....]                 .....]                 .....]                 .....]
 * So to get the second row af data you have to get the value for the target from all columns and the
 * add them the to data row one by one.
 *  The first column in the list is ALQAYS assumed to hold the target identifiers (UNIPROT-AC/ENTRY).
 * -
 * @ColumnDefs is an extract of the column defintions to give to the data table (ag-grid) to show the
 * columns and map field data to the correct column by its Name
 * MUTATIONS
 * @readData - reads the data file and create the ColumnList and the Column Defs.
 * -
 * STATE
 * @DataWindow - Holds the windows of data to be shown in the datatable - this is all the ACTIVE DataColumns
 * represented as data rows for the ag-grid. Only active Datacolumns are in the datawindow, others are loaded
 * and in the ColumnLust but not in the DataWindows. Modifying the Active columns requires DataWindow to be rebuild.
 * -
 * @getDataWindow - Build the DataWindow - see above
 */

/* eslint-disable no-tabs */
/* eslint-disable no-multi-str */
import Vue from 'vue'
import { parseRows } from './DataParser.js'
import { DataColumn } from './DataColumn.js'
import { DataField } from './DataField.js'
import { TemplateHandler } from './TemplateHandler'
import { formatToXDigitPrecision, formatToFixed, formatToScientific } from './FormatTools'

const IdentifierColumnName = DataColumn.IdentifierColumnName

let columnList = []
let columnDefs = []
let columnGrossList = []
let rawDataWindow = []

// ******  INTERNAL FUNCTIONS FOR THE DATASET ******
//

/**
 * Read Data from sample file using parseRows in FileDataSource
 * Creates the ColumnList of all column in the datafile and the ColumnDefs of
 * all active DataColumns to be shown in the datatable (ag-grid)
 */
function readDataFile (specZipped, dataZipped) {
  try {
    const rawData = decompressData(dataZipped)
    const spec = JSON.parse(decompressData(specZipped))
    columnList = []
    // console.log('Parsing Rows')
    columnList = parseRows(spec, rawData, '\t') // Read and parse the file to a list of DataColumn
    columnGrossList = columnList.slice()
    // columnList = columnList.filter(column => column.InDefaultView) // Skip columns that are not to be included
    // console.log('Got ' + columnList.length + ' columns in file')
    columnList.sort((a, b) => a.Order - b.Order) // Sort columns by the specified order
    TemplateHandler.substitutePlaceholders(columnList)
    columnList.forEach((column) => {
      column.FieldName = column.FieldName.replace('.', '-dot-')
      // Create the column definition for ag-grid for each column. Use the Type to determine the renderer ie. vue component for the cell
      // console.log('Definition created for "' + column.FieldName)
      if (column.InDefaultView) { // Hard code some active columns
        column.Active = true
      } else {
        column.Active = false
      }
      column.ColumnDefinition = createColumnDefForAGGrid(column)
    })
    createColumnDefList()
  } catch (err) {
    console.log('Parsing failed')
    console.log(err)
  }
}

function decompressData (compressedDataBuffer) {
  const buffer = Buffer.from(compressedDataBuffer, 'base64')
  const zlib = require('zlib')
  const data = new TextDecoder().decode(zlib.gunzipSync(buffer))
  return data
}

function fetchAllRawData () {
  const allRowData = {}
  rawDataWindow.forEach((row) => {
    const rowId = row[IdentifierColumnName].Attributes.value
    allRowData[rowId] = {}
    columnList.forEach((column) => {
      const field = column.getDataForTarget(rowId)
      if (field.Attributes === undefined) {
        allRowData[rowId][column.FieldName] = field
      } else {
        allRowData[rowId][column.FieldName] = field.Attributes.value
      }
    })
  })
  return allRowData
}

function addDataToWindow (columnId) {
  // console.log('Adding: ' + columnId)
  const addedColumn = columnGrossList.find(elem => elem.FieldName === columnId)
  rawDataWindow.forEach((row) => {
    const value = addedColumn.getDataForTarget(row[IdentifierColumnName].Attributes[DataField.Value])
    Vue.set(row, addedColumn.FieldName, value)
  })
}

function deleteDataFromWindow (columnId) {
  // console.log('Deleting: ' + columnId)
  rawDataWindow.forEach((row) => {
    Vue.delete(row, columnId)
  })
}

function createColumnDefList () {
// Iterate trough all columns and create the columnGroups referenced for a column if needed. Then add the columnn o the column group
// children if a group is set. Otherwise just a the column to the columnDef list.
  columnDefs = []
  const columnGroupList = []
  columnList.forEach((column) => {
    if (column.GroupName !== '') {
      let columnGroup = columnGroupList.find((group) => { // Find the column group referenced
        return group.headerName === column.GroupName
      })
      if (columnGroup === undefined) { // Created the column group if it does not exist
        columnGroup = {
          headerName: column.GroupName,
          children: []
        }
        columnGroupList.push(columnGroup) // Add the column group to existing columngroups
        columnDefs.push(columnGroup)
      }
      if (column.SubGroupName !== '') {
        let columnSubGroup = columnGroup.children.find((subGroup) => {
          return column.SubGroupName === subGroup.headerName
        })
        if (columnSubGroup === undefined) {
          columnSubGroup = {
            headerName: column.SubGroupName,
            children: []
          }
          columnGroup.children.push(columnSubGroup)
        }
        columnSubGroup.children.push(column.ColumnDefinition)
      } else {
        columnGroup.children.push(column.ColumnDefinition)
      }
    } else {
      columnDefs.push(column.ColumnDefinition)
    }
  })
}

function createColumnDefForAGGrid (column) {
  /**
   *  column.Type, column.FieldName, column.Description,
        column.DisplayName, column.Active, column.LockedInView)
   * Creates the approproiate structure for the column to be given to AG grid columns definitiion.
   * Based on the DataColumn.Type the correct struture is generated
   * @Returns Nothing but modifies the column object
   */
  let returnColumnDefinition = {}
  // console.log(column)

  // By Default we assume the column is text because we can render most values as text
  // so if the type is not known we use text
  returnColumnDefinition = {
    resizable: true,
    width: 100,
    hide: !column.Active,
    headerName: column.DisplayName,
    groupDisplayName: column.GroupName + (column.SubGroupName !== '' ? ' / ' + column.SubGroupName : ''),
    field: column.FieldName,
    cellRendererFramework: 'TextDataField',
    sortable: true,
    headerTooltip: column.Description,
    suppressMenu: false,
    menuTabs: [],
    comparator: DataColumn.lexicalComparator,
    keyCreator: params => params.value.Attributes ? params.value.Attributes.value : params.value
  }
  if (column.Type === DataColumn.TextType) {
    returnColumnDefinition.valueFormatter = (params) => {
      if (params.value === '-') {
        return column.MissingValue
      }
      if (params.value === '') {
        return column.MissingValue
      }
      if (params.value === 'NA') {
        return column.MissingValue
      }
    }
  }
  // Handle the specific types
  if (column.Type === DataColumn.IntType) {
    returnColumnDefinition.cellRendererFramework = 'IntegerDataField'
    returnColumnDefinition.comparator = DataColumn.numericComparator
  }
  if (column.Type === DataColumn.BooleanType) {
    returnColumnDefinition.cellRendererFramework = 'BooleanDataField'
    returnColumnDefinition.comparator = DataColumn.booleanComparator
    returnColumnDefinition.cellClassRules = {
      'boolean-field-true': params => params.value.Attributes[DataField.Value] === true,
      'boolean-field-false': params => params.value.Attributes[DataField.Value] === false
    }
    returnColumnDefinition.sortingOrder = ['desc', 'asc', null]
    // returnColumnDefinition.valueFormatter = (params) => {
    //   // console.log(params.value.Attributes.value)
    //   if (!params.value || !params.value.Attributes) {
    //     return column.MissingValue
    //   }
    //   if (params.value.Attributes[DataField.Value] && typeof params.value.Attributes[DataField.Value] === 'boolean') {
    //     return 'Y'
    //   } else if (!params.value.Attributes[DataField.Value] && typeof params.value.Attributes[DataField.Value] === 'boolean') {
    //     return 'N'
    //   } else {
    //     return column.MissingValue
    //   }
    // }
  }

  if (column.Type === DataColumn.RealType) {
    returnColumnDefinition.cellRendererFramework = 'RealDataField'
    returnColumnDefinition.minWidth = 80
    returnColumnDefinition.comparator = DataColumn.numericComparator
    returnColumnDefinition.format = column.RealFormat
    returnColumnDefinition.valueFormatter = (params) => {
      if (params.value === '-') {
        return column.MissingValue
      }
      if (params.value === '') {
        return column.MissingValue
      }
      if (params.value === 'NA') {
        return column.MissingValue
      }
      if (column.RealFormat === DataColumn.RealFormatFloat) {
        return formatToFixed(params.value, column.RealDecimals)
      } else if (column.RealFormat === DataColumn.RealFormatScientific) {
        return formatToScientific(params.value, column.RealDecimals)
      } else {
        return formatToXDigitPrecision(params.value, 2)
      }
    }
  }

  if (column.Type === DataColumn.ScoreType) {
    returnColumnDefinition.cellRendererFramework = 'ScoreDataField'
    returnColumnDefinition.comparator = DataColumn.numericComparator
  }
  if (column.Type === DataColumn.PvalueType) {
    returnColumnDefinition.cellRendererFramework = 'RealDataField'
    returnColumnDefinition.comparator = DataColumn.numericComparator
    returnColumnDefinition.cellClassRules = {
      'p-value-field-001': params => params.value.Attributes[DataField.Value] < 0.001,
      'p-value-field-01': params => (params.value.Attributes[DataField.Value] >= 0.001 && params.value.Attributes[DataField.Value] < 0.01),
      'p-value-field-05': params => (params.value.Attributes[DataField.Value] >= 0.01 && params.value.Attributes[DataField.Value] < 0.05)
    }
    returnColumnDefinition.valueFormatter = (params) => {
      if (params.value.Attributes[DataField.Value] === 'NA') {
        return column.MissingValue
      }
      return formatToScientific(params.value.Attributes[DataField.Value], 2)
    }
  }
  if (column.Type === DataColumn.LogFcType) {
    returnColumnDefinition.cellRendererFramework = 'RealDataField'
    returnColumnDefinition.comparator = DataColumn.numericComparator
    returnColumnDefinition.padPositive = true
    returnColumnDefinition.cellClassRules = {
      'logfc-pos-15': params => params.value.Attributes.value > 1.5,
      'logfc-neg-15': params => params.value.Attributes.value < -1.5,
      'logfc-pos': params => (params.value.Attributes.value <= 1.5 && params.value.Attributes.value > 0),
      'logfc-neg': params => (params.value.Attributes.value >= -1.5 && params.value.Attributes.value < 0)
    }
    returnColumnDefinition.valueFormatter = (params) => {
      if (params.value.Attributes.value === 'NA') {
        return column.MissingValue
      }
      return formatToXDigitPrecision(params.value.Attributes.value, 2)
    }
  }
  if (column.Type === DataColumn.LinkType) {
    returnColumnDefinition.cellRendererFramework = 'LinkDataField'
    returnColumnDefinition.sortable = false
  }
  if (column.Type === DataColumn.HpaCategory) {
    returnColumnDefinition.cellRendererFramework = 'TextDataField'
    returnColumnDefinition.cellClassRules = {
      'hpa-tissue-cell-region-enriched': params => Boolean(params.value.Attributes.value.match(/Tissue enriched|Cell type enriched|Region enriched/g)),
      'hpa-group-enriched': params => Boolean(params.value.Attributes.value.match(/Group enriched/g)),
      'hpa-tissue-cell-region-enhanced': params => Boolean(params.value.Attributes.value.match(/Tissue enhanced|Cell type enhanced|Region enhanced/g))
    }
  }
  if (column.Type === DataColumn.NormalizedCount) {
    returnColumnDefinition.cellRendererFramework = 'RealDataField'
    returnColumnDefinition.padPositive = true
    returnColumnDefinition.comparator = DataColumn.numericComparator
    returnColumnDefinition.cellClassRules = {
      'normalizedcount-n15': params => params.value.Attributes[DataField.Value] < -15,
      'normalizedcount-n15-n10': params => params.value.Attributes[DataField.Value] > -15 && params.value.Attributes[DataField.Value] <= -10,
      'normalizedcount-n10-n5': params => params.value.Attributes[DataField.Value] > -10 && params.value.Attributes[DataField.Value] <= -5,
      'normalizedcount-n5-0': params => params.value.Attributes[DataField.Value] > -5 && params.value.Attributes[DataField.Value] < 0,
      'normalizedcount-0': params => params.value.Attributes[DataField.Value] === 0,
      'normalizedcount-0-5': params => params.value.Attributes[DataField.Value] > 0 && params.value.Attributes[DataField.Value] <= 5,
      'normalizedcount-5-10': params => params.value.Attributes[DataField.Value] > 5 && params.value.Attributes[DataField.Value] <= 10,
      'normalizedcount-10-15': params => params.value.Attributes[DataField.Value] > 10 && params.value.Attributes[DataField.Value] <= 15,
      'normalizedcount-15': params => params.value.Attributes[DataField.Value] > 15
    }
    returnColumnDefinition.valueFormatter = (params) => {
      if (params.value.Attributes.value === 'NA') {
        return column.MissingValue
      }
      return params.value.Attributes.value.toString()
    }
  }
  if (column.Type === DataColumn.LogCPM) {
    returnColumnDefinition.cellRendererFramework = 'RealDataField'
    returnColumnDefinition.padPositive = true
    returnColumnDefinition.comparator = DataColumn.numericComparator
    returnColumnDefinition.cellClassRules = {
      'logcpm-neg': params => params.value.Attributes[DataField.Value] < 0,
      'logcpm-0-2': params =>
        params.value.Attributes[DataField.Value] > 0 &&
        params.value.Attributes[DataField.Value] <= 2,
      'logcpm-2-4': params =>
        params.value.Attributes[DataField.Value] > 2 &&
        params.value.Attributes[DataField.Value] <= 4,
      'logcpm-4-6': params =>
        params.value.Attributes[DataField.Value] > 4 &&
        params.value.Attributes[DataField.Value] < 6,
      'logcpm-6-8': params =>
        params.value.Attributes[DataField.Value] > 6 &&
        params.value.Attributes[DataField.Value] <= 8,
      'logcpm-8-10': params =>
        params.value.Attributes[DataField.Value] > 8 &&
        params.value.Attributes[DataField.Value] <= 10,
      'logcpm-10-12': params =>
        params.value.Attributes[DataField.Value] > 10 &&
        params.value.Attributes[DataField.Value] <= 12,
      'logcpm-12-14': params =>
        params.value.Attributes[DataField.Value] > 12 &&
        params.value.Attributes[DataField.Value] <= 14,
      'logcpm-14': params => params.value.Attributes[DataField.Value] > 14
    }
    returnColumnDefinition.valueFormatter = (params) => {
      if (params.value.Attributes.value === 'na') {
        return column.MissingValue
      }
      return formatToFixed(params.value.Attributes[DataField.Value], 2)
    }
  }
  if (column.Type === DataColumn.LinkTextType) {
    returnColumnDefinition.cellRendererFramework = 'LinkTextDataField'
  }

  if (column.Width) {
    returnColumnDefinition.width = column.Width
  }

  // Handle columns that are fixed in the view. Gene name and score. Searchable and pinned.
  if (column.LockedInView) {
    if (column.Type === DataColumn.LinkType) {
      returnColumnDefinition.getQuickFilterText =
        (params) => {
          if (params.value) {
            return (params.value.Attributes ? params.value.Attributes.label : params.value)
          } else {
            return params.value
          }
        }
    }
    returnColumnDefinition.pinned = 'left'
    returnColumnDefinition.minWidth = 100
  }

  if (column.Searchable) {
    returnColumnDefinition.getQuickFilterText =
      (params) => {
        if (params.value) {
          return (params.value.Attributes ? params.value.Attributes.label : params.value)
        } else {
          return params.value
        }
      }
  }

  return returnColumnDefinition
}

/**
 * Builds the DataWindow - ie. dataset to be shown in the ag-grid visible data table. The
 * Windows is made of the data from all the active data columns.
 * @returns A list of data rows as needed by ag-grid with the data in the active columns
 */

function getDataWindow () {
  rawDataWindow = []
  // Get the target identifiers. The are keys in all DataColumn data maps
  const targetIdList = Object.keys(columnList[0].DataMap)
  let index = 0
  while (index < targetIdList.length) {
    // eslint-disable-next-line no-console
    //    console.log('Fetching data ' + startIndex)
    const targetId = targetIdList[index] // Get the key for this row (the target shown)
    const fieldmap = {}
    columnList.forEach((columnElement) => {
      const FieldNameWithDots = columnElement.FieldName.replace('.', '-dot-')
      const isIDColumn = FieldNameWithDots === IdentifierColumnName
      if (columnElement.InDefaultView || isIDColumn) {
        fieldmap[columnElement.FieldName] = columnElement.getDataForTarget(targetId)
      }
      fieldmap.favorite = false
    })
    rawDataWindow.push(fieldmap) // Add the created row to the returned list of rowa
    //    console.log(fieldmap)
    if (rawDataWindow.length % 100 === 0) {
      console.log('Got ' + rawDataWindow.length + ' rows for data window')
    }
    index++
  }
  return rawDataWindow
}

// ******  EXTERNAL FUNCTIONS FOR THE DATASET ******
export const getters = {
  plainColumnDefs (state) {
    const plainColumns = []
    state.ColumnDefinitions.forEach((columnDef) => {
      if (!Object.prototype.hasOwnProperty.call(columnDef, 'children')) {
        plainColumns.push(columnDef)
      } else {
        columnDef.children.forEach((child) => {
          if (!Object.prototype.hasOwnProperty.call(child, 'children')) {
            plainColumns.push(child)
          } else {
            child.children.forEach((subChild) => {
              plainColumns.push(subChild)
            })
          }
        })
      }
    })
    return plainColumns
  },
  dataReloadNeeded (state) {
    return state.DataReloadNeeded
  }
}

export const state = () => ({
  DataWindow: [], // Data in ag-grid format to be shown
  Count: 0, // Number of targets in the list
  ColumnDefinitions: columnDefs, // Columns definitions for ag-grid
  DataReloadNeeded: false // indicates that authentication changed and reload is needed
})

export const mutations = {
  readData (state, { specification, data }) { // Read data from a file
    columnDefs = []
    columnList = []
    state.ColumnDefinitions = []
    state.DataWindow = rawDataWindow
    readDataFile(specification, data)
    state.ColumnDefinitions = columnDefs
    state.DataWindow = getDataWindow(0, 25000)
  },
  rankColumn (state, { name }) {
  //    const columnAGDefinition = columnDefs.find(def => def.headerName === name)
    const rankedColumn = columnList.find((column) => {
      return column.FieldName === name
    })
    //    columnDefs.push(columnAGDefinition) // !!!! Weird hack to force an update of the column type
    //    columnDefs.pop() // !!!! Weird hack to force an update of the column type - we add and remove a column to force a refresh
    rankedColumn.rankValues()
  },
  setFavorite (state, index) {
    state.DataWindow[index].favorite = true
  },
  removeFavorite (state, index) {
    state.DataWindow[index].favorite = false
  },
  setReloadNeeded (state, value) {
    state.DataReloadNeeded = value
  },
  addData (state, { columnId }) {
    addDataToWindow(columnId)
  },
  deleteData (state, { columnId }) {
    deleteDataFromWindow(columnId)
  }
}

export const actions = {
  updateDataWindow ({ commit }, { columnState }) {
    const inDataWindow = Object.keys(rawDataWindow[0])
    return new Promise((resolve) => {
      columnState.forEach((column) => {
        const isIDColumn = column.colId === IdentifierColumnName // Never delete the ID column from rawDataWindow
        if (column.hide && inDataWindow.includes(column.colId) && !isIDColumn) {
          commit('deleteData', { columnId: column.colId })
        } else if (!column.hide && !inDataWindow.includes(column.colId)) {
          commit('addData', { columnId: column.colId })
        }
      })
      resolve()
    })
  },
  getAllRawData (_, Id) {
    return fetchAllRawData(Id)
  },
  addToFavorites ({ commit, state }, uniProtId) {
    const index = state.DataWindow.findIndex((data) => {
      return data[DataColumn.IdentifierColumnName].Attributes.value === uniProtId
    })
    commit('setFavorite', index)
  },
  removeFromFavorites ({ commit, state }, uniProtId) {
    const index = state.DataWindow.findIndex((data) => {
      return data[DataColumn.IdentifierColumnName].Attributes.value === uniProtId
    })
    commit('removeFavorite', index)
  },
  requireReload ({ commit }, value) {
    commit('setReloadNeeded', value)
  }
}
