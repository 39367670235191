//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginPrompt from '@/components/LoginPrompt'
import PasswordResetRequest from '@/components/PasswordResetRequest'
import PasswordReset from '@/components/PasswordReset'

export default {
  components: {
    // We don't need this? nuxt????
    LoginPrompt,
    PasswordResetRequest,
    PasswordReset
  },
  data () {
    return {
      showLoginPopUp: false,
      showPasswordResetPopUp: false,
      showPasswordResetRequestPopUp: false
    }
  },
  computed: {
    isLoggedIn () {
      if (this.$store.getters['Authentication/getToken']) {
        return true
      }
      return false
    },
    showLogin () {
      if (this.isLoggedIn) {
        return false
      }
      if (this.showPasswordResetPopUp) {
        return false
      }
      if (this.showPasswordResetRequestPopUp) {
        return false
      }
      return true
    },
    UserName () {
      return this.$store.getters['Authentication/getUserName']
    }
  },
  created () {
    if ('reset_password' in this.$route.query) {
      this.showLoginPopUp = false
      this.showPasswordResetPopUp = true
      this.showPasswordResetRequestPopUp = false
    }
  },
  methods: {
    handleLoginClick () {
      this.showLoginPopUp = true
    },
    handleLoginCancelled () {
      this.showLoginPopUp = false
    },
    handleLoginSuccesfull () {
      this.$store.dispatch('DataSet/requireReload', true)
      this.showLoginPopUp = false
    },
    handleLogout () {
      this.logout()
    },
    handlePasswordResetRequestCancelled () {
      this.showPasswordResetRequestPopUp = false
    },
    handlePasswordResetRequestClosed () {
      this.showPasswordResetRequestPopUp = false
    },
    handlePasswordResetCancelled () {
      this.showPasswordResetPopUp = false
      // Remove token reset URL params:
      this.$router.push({ query: '' })
    },
    handlePasswordResetClosed () {
      this.showPasswordResetPopUp = false
      // Remove token reset URL params:
      this.$router.push({ query: '' })
    },
    handleShowPasswordResetRequestPopUp () {
      this.showLoginPopUp = false
      this.showPasswordResetRequestPopUp = true
    },
    clearAuthentication () {
      if (this.$cookies !== undefined) {
        this.$cookies.remove('Token', null, this.$config.cookieDomain)
        this.$cookies.remove('UserName', null, this.$config.cookieDomain)
      }
      this.$store.commit('Authentication/setToken', '')
      this.$store.commit('Authentication/setUserName', '')
    },
    logout () {
      const data = { config: this.$config, username: '', password: '' }
      this.$store.dispatch('Authentication/login', data)
        .then((response) => {
          this.clearAuthentication()
          this.$store.dispatch('DataService/dropDb')
        }).catch(() => {
          this.clearAuthentication()
          this.$store.dispatch('DataService/dropDb')
        })
      // console.log('Logged out finished')
    },
    cancelClick () {
      this.$emit('loginCancelled')
    },
    openKnow () {
      window.open('https://inbio-discover.com/know.html', '_blank')
    },
    openRevelen () {
      window.open('https://zs-revelen.com/', '_blank')
    },
    openExpressionExplorer () {
      window.open(this.$config.expressionExplorerURL, '_blank')
    }
  }
}

