const tpsFileVersion = 1.0

export const actions = {
  saveSession ({ state }, payload) {
    const data = {
      version: tpsFileVersion,
      columnStates: payload.columnStates,
      filters: payload.filters,
      favoritesActive: payload.favoritesActive,
      favoriteIds: payload.favoriteIds,
      favoritesIdColumn: payload.favoritesIdColumn,
      geneListFilterSet: payload.geneListFilterSet,
      geneListColumn: payload.geneListColumn,
      geneListName: payload.geneListName

    }
    const dataString = JSON.stringify(data)
    const blob = new Blob([dataString], { type: 'text/plain' })
    const e = document.createEvent('MouseEvents')
    const a = document.createElement('a')
    a.download = 'session.tps'
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initEvent('click', true, false)
    a.dispatchEvent(e)
  }
}
