/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars

/** DataColumn is the central container of data in the system!
* Wraps a data field to be shown in the grid. Stores predefined attributes for this fields to
* allow for sophisticated rendering rather than just parsing a value to the Grid it is now
* clear if a text, icon or link should be shown and handles bu the grid cell
*/

export class DataField {
  static Value = 'value'
  static Icon = 'icon'
  static Text = 'label'
  static Link = 'link'
  static Rank = 'rank'

  constructor (column) {
    this.Attributes = {}
    this.Column = column
  }

  // Value - Holds the value for filtering and sorting purposes. Also shown if type is numerical.
  setValue (val) {
    this.Attributes[DataField.Value] = val
  }

  getValue () {
    return this.Column.UseRanking ? this.Attributes[DataField.Rank] : this.Attributes[DataField.Value]
  }

  // Icon - holds an image reference (in static folder).
  setIcon (val) {
    this.Attributes[DataField.Icon] = val
  }

  getIcon () {
    return this.Attributes[DataField.Icon]
  }

  // Text - holds a text that provides a reader friendly representation of th value.
  setText (val) {
    this.Attributes[DataField.Text] = val
  }

  getText () {
    return this.Attributes[DataField.Text]
  }

  // Link - holds a link string "http://blabla.bla.com" to be used on cell click and opened
  // in a pop-up or new window.
  setLink (val) {
    this.Attributes[DataField.Link] = val
  }

  getLink () {
    return this.Attributes[DataField.Link]
  }

  // Rank - Holds the rank for ordered datatypes. DataColumn defines ranking order (High/Low)
  setRank (val) {
    this.Attributes[DataField.Rank] = val
  }

  getRank () {
    return this.Attributes[DataField.Rank]
  }
}
