/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars

import { DataField } from './DataField'

/** DataColumn is the central container of data in the system!
* @Type is the data type of the value in the column must be IntType, TextType or RealType'
* @Name is the name of the column. Used to map the column/datafield in the dataset to the column in the grid.
* @Description is the textual desription of the column data. To be shown to users as guidance.
* @Order is the in which the column was read from the file
* @DataMap is the map of target_id to data object for this column. Example
*   { 'INS_HUMAN' : <DataField1>,
      'IDE_HUMAN' : <DataField2>,
      'CD4_HUMAN' : <DataField3> ...
    ...
    }
*
* @ColumnDefinition is the column definition to be used in the ag-grid data grid. It depends
* on the column Name (identifier in ag grid) and the column type (set the ag-grid redering component)
* @Active determines if the column is to be shown in the ag-grid at all. Most column are hidden.
* Add/remove column in the UI should set this bool
*/

// Hardcoded as the only accepted 'NA' value.  ¯\(°_o)/¯ - Marc
const naSymbol = 'NA'

function getNaSortValue (a, b, isInverted) {
  if ((a === naSymbol) && (b === naSymbol)) {
    return 0
  }
  if (a === naSymbol) {
    return isInverted ? -1 : 1
  }
  return !isInverted ? -1 : 1
}

export class DataColumn {
  static IntType = 'integer'
  static TextType = 'text'
  static RealType = 'real'
  static LinkType = 'link'
  static LinkTextType = 'linktext'
  static ScoreType = 'score'
  static PvalueType = 'pvalue'
  static LogFcType = 'logfc'
  static BooleanType = 'boolean'
  static RankLowestIsBest = 'low'
  static RankHighestIsBest = 'high'
  static RealFormatScientific = 'scientific'
  static RealFormatFloat = 'float'
  static HpaCategory = 'hpacategory'
  static NormalizedCount = 'normalizedcount'
  static LogCPM = 'logcpm'
  static IdentifierColumnName = 'UniProtAC'
  static FavoritesColumnName = 'PrimaryGene'
  static naSymbol = naSymbol
  // static IdentifierColumnName = 'Protein_info-dot-PrimaryGeneName'

  constructor (type, name, description, order) {
    this.Type = type
    this.FieldName = name
    this.Description = description
    this.DataMap = {}
    this.ColumnDefinition = {}
    this.Active = true
    this.RankType = DataColumn.RankLowestIsBest
    this.UseRanking = false
    this.Width = 100
    this.GroupName = '' // empty string means no group
    this.SubGroupName = ''
    this.InDefaultView = false
    this.Order = 0
    this.RealDecimals = 2 // Number of decimals on floats 234.34 or scientifics 123.23123123 will be 1.23E02
    this.RealFormat = DataColumn.RealFormatFloat
    this.Link = ''
    this.Icon = '.'
    this.MissingValue = 'N/A'
    this.IncludeColumn = false
    this.LockedInView = false
    this.DisplayName = ''
  }

  pushData (target, value) {
    // Sets the value of the field in this column for this target @see DataMap above
    this.DataMap[target] = value
  }

  getDataForTarget (target) {
    // Gets the field value in this column for the target @see DataMap above
    return this.DataMap[target]
  }

  isIdentifier () {
    // Determines if this column is the identifier column
    return this.FieldName === DataColumn.IdentifierColumnName
  }

  // Comparators for sorting
  rankingComparator (targetA, targetB) {
    return targetA.value - targetB.value
  }

  rankValues () {
    this.UseRanking = true
    const targetIds = Object.keys(this.DataMap)
    const targetValuePairs = []
    for (let index = 0; index < targetIds.length; index++) {
      if (this.DataMap[targetIds[index]].Attributes) {
        targetValuePairs.push({ target: targetIds[index], value: this.DataMap[targetIds[index]].Attributes[DataField.Value] })
      } else {
        targetValuePairs.push({ target: targetIds[index], value: this.DataMap[targetIds[index]].value })
      }
    }
    targetValuePairs.sort(this.rankingComparator)
    let ranking = 1
    for (let index2 = 0; index2 < targetValuePairs.length; index2++) {
      const field = this.DataMap[targetValuePairs[index2].target]
      if (field.Attributes) {
        field.Attributes.value = ranking
      } else {
        field.value = ranking
      }
      ranking++
    }
  }

  static numericComparator (valueA, valueB, nodeA, nodeB, isInverted) {
    if (valueA.Attributes && valueB.Attributes) {
      const numberA = valueA.Attributes[DataField.Value]
      const numberB = valueB.Attributes[DataField.Value]
      if (isFinite(valueA.Attributes[DataField.Value] - valueB.Attributes[DataField.Value])) {
        return valueA.Attributes[DataField.Value] - valueB.Attributes[DataField.Value]
      } else {
        return getNaSortValue(numberA, numberB, isInverted)
      }
    } else if (isFinite(valueA - valueB)) { // Handle reals that are set as raw values and not
      return valueA - valueB
    } else {
      return getNaSortValue(valueA, valueB, isInverted)
    }
  }

  static booleanComparator (valueA, valueB, nodeA, nodeB, isInverted) {
    const missingValue = naSymbol
    const a = valueA.Attributes ? valueA.Attributes.value : valueA
    const b = valueB.Attributes ? valueB.Attributes.value : valueB
    if ((a === missingValue) || (b === missingValue)) {
      return getNaSortValue(a, b, isInverted)
    } else if (a) {
      return 1
    } else {
      return -1
    }
  }

  static lexicalComparator (valueA, valueB, nodeA, nodeB, isInverted) {
    const missingValue = naSymbol
    const a = valueA.Attributes ? valueA.Attributes.value : valueA
    const b = valueB.Attributes ? valueB.Attributes[DataField.Text] : valueB
    if ((a === missingValue) || (b === missingValue)) {
      return getNaSortValue(a, b, isInverted)
    }
    if (a > b) {
      return 1
    } else if (a === b) {
      return 0
    } else if (a < b) {
      return -1
    }
  }
}
