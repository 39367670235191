import { DataColumn } from './DataColumn.js'
import { DataField } from './DataField.js'

const IdentifierColumnName = DataColumn.IdentifierColumnName

// import fileData from '~/assets/txt/targets_anonymous_confidential_sample.txt'
// import fileSpecification from '~/assets/txt/targets_anonymous_confidential_sample.specification.json'
// import fileData from '~/assets/txt/targets_anonymous_confidential_full.txt'
// import fileData from '~/assets/txt/TargetPortal_dataimport_sample.txt'
// import fileSpecification from '~/assets/txt/TargetPortal_dataimport_sample.specification.json'

// eslint-disable-next-line no-unused-vars
export function parseRows (specification, data, separator) {
  // Builds the Columns for the dataset from the first line and then maps fields by going line(row) by line(row) through the text amd split
  // the line text with the separator.
  // Data fields are added to the relevant Datacolumn after processing the raw text accoriding to the DataColumn type. ie. TextType is
  // unaltered and RealType get "," replaced and then parses the number and so on.
  // @return a list of DataColumns with data values mapped for each target (UNIPROT-AC) to the value @See DataColumn

  const fileData = data
  const fileSpecification = specification
  const columnsRead = []
  const columnDefinitionsArray = readColumnDefinitions(fileSpecification)
  // Parse the header line and create the DataColumn for each header field
  let splitDataRows = fileData.split('\n')
  splitDataRows = splitDataRows.map(row => row.split(separator))
  const dataRows = []
  const targetRowCount = splitDataRows[0].length
  splitDataRows.forEach((row) => {
    if (row.length === targetRowCount) {
      dataRows.push(row)
    }
  })

  // Assume column name are in the 1st line of the mock up data source
  const columnTable = dataRows[0].map((_, colIndex) => dataRows.map(row => row[colIndex])) // transpose table -> rows made to columns

  // Walkthrough each row of the data file (called columndata since they are transposed !!)
  const proteinIDList = []
  columnTable.forEach((columnData) => {
    const columnName = columnData[0]
    const columnDefinition = columnDefinitionsArray[columnName]
    if (columnDefinition) {
      const columnNameWithDots = columnName.replace('.', '-dot-')
      const isIDColumn = columnNameWithDots === IdentifierColumnName
      if (isIDColumn || columnDefinition.IncludeColumn) { // Push column with data filled in
        columnData = columnData.slice(1) // First entry is the column header name - skip it
        const parsedDataColumn = readDataIntoColumn(columnDefinition, columnData, proteinIDList, isIDColumn)
        columnsRead.push(parsedDataColumn)
      }
    } else {
      console.log('Cannot find column "' + columnName + '" Skipped column')
    }
  })

  // Find columns definitions that are not mapped (not present in the data file) and add them.
  // Their content should be auto generated from the definition opnly using icons, links and/or placeholder references
  // to other columns
  const columnDefinitionsKeys = Object.keys(columnDefinitionsArray)
  columnDefinitionsKeys.forEach((definitionKey) => {
    const columnMapped = columnsRead.find(col => (
      col.FieldName.toLowerCase() === definitionKey.toLowerCase()))

    if (!columnMapped) {
      const emptyData = proteinIDList.map(() => '') // create an array of empty string to use as 'data'. Data is empty for a generated field.
      const parsedDataColumn = readDataIntoColumn(columnDefinitionsArray[definitionKey], emptyData, proteinIDList, false)
      if (parsedDataColumn.IncludeColumn) {
        columnsRead.push(parsedDataColumn)
      }
    }
  })

  return columnsRead // Return data read as a list of column with the data values for each target
}

function readDataIntoColumn (columnDefinition, dataInColumn, proteinIDList, isIDColumn) {
  const dataColumn = Object.assign(new DataColumn(), columnDefinition)
  if (isIDColumn) {
    dataInColumn.forEach((value, i) => {
      parseField(dataColumn, value, value, isIDColumn) // The id column has the same key as value since values are refernced by the protein id
      proteinIDList.push(value) // add the protein key to the list of keys for lookup in else part of if .....
    })
  } else {
    dataInColumn.forEach((value, i) => {
      parseField(dataColumn, proteinIDList[i], value, isIDColumn) // Store the value for the correct protein ID
    })
  }
  return dataColumn
}

// Reads columnspecfications from a file name <data-file>.specification.json
function readColumnDefinitions (specification) {
/**
 * Get JSON representation of a list of column specifications and return a list of DataColumn object
 * made from that list.
 * @specification - raw json text holding a list of columnDefinition objects
 *
 * @return a List of DataColumn as read from JSON serialization
 */
  const columnLookUpTable = {}
  const columnList = specification

  columnList.forEach((columnDef) => {
    // columnDef.FieldName = columnDef.FieldName.replace('.', '_')
    // columnLookUpTable[columnDef.GroupName + '.' + columnDef.FieldName] = columnDef
    columnLookUpTable[columnDef.FieldName] = columnDef
    if (columnDef.Format.toLowerCase() === 'text') {
      columnDef.Type = DataColumn.TextType
    }
    if (columnDef.Format.toLowerCase() === 'link') {
      columnDef.Type = DataColumn.LinkType
    }
    if (columnDef.Format.toLowerCase() === 'linktext') {
      columnDef.Type = DataColumn.LinkTextType
    }
    if (columnDef.Format.toLowerCase() === 'boolean') {
      columnDef.Type = DataColumn.BooleanType
    }
    if (columnDef.Format.toLowerCase() === 'integer') {
      columnDef.Type = DataColumn.IntType
    }
    if (columnDef.Format.toLowerCase() === 'scientific') {
      columnDef.Type = DataColumn.RealType
      columnDef.RealFormat = DataColumn.RealFormatScientific
      columnDef.RealDecimals = 2
    }
    if (columnDef.Format.toLowerCase().includes('float')) {
      columnDef.Type = DataColumn.RealType
      columnDef.RealFormat = DataColumn.RealFormatFloat
      columnDef.RealDecimals = 2
      if (columnDef.Format.toLowerCase().includes(':')) {
        const decimals = columnDef.Format.substring(columnDef.Format.indexOf(':') + 1)
        columnDef.RealDecimals = parseFloat(decimals)
      }
    }
    if (columnDef.Format.toLowerCase().includes('score')) {
      columnDef.Type = DataColumn.ScoreType
    }
    if (columnDef.Format.toLowerCase().includes('pvalue')) {
      columnDef.Type = DataColumn.PvalueType
      columnDef.RealFormat = DataColumn.RealFormatScientific
    }
    if (columnDef.Format.toLowerCase().includes('logfc')) {
      columnDef.Type = DataColumn.LogFcType
      columnDef.RealFormat = DataColumn.RealFormatScientific
    }
    if (columnDef.Format.toLowerCase().includes('hpacategory')) {
      columnDef.Type = DataColumn.HpaCategory
    }
    if (columnDef.Format.toLowerCase().includes('normalizedcount')) {
      columnDef.Type = DataColumn.NormalizedCount
    }
    if (columnDef.Format.toLowerCase().includes('logcpm')) {
      columnDef.Type = DataColumn.LogCPM
    }
  })

  return columnLookUpTable
}

function toBooleanValue (value, missingValue) {
// Helper function to convert different boolean notations into true/false/missing
  if (!value) { // set undefined as missing value
    value = missingValue
  }
  if (value > 0 || value.toLowerCase() === 'y' || value.toLowerCase()[0] === 't') {
    return true
  } else if (value <= 0 || value.toLowerCase() === 'n' || value.toLowerCase()[0] === 'f') {
    return false
  } else {
    return missingValue
  }
}

function parseField (column, targetID, fieldValue, isIDField) {
  // console.log('parse column:' + column.FieldName + '    target:' + targetID + '  val:' + fieldValue)

  const dataValueField = new DataField(column)
  if (column.Type.includes('##')) { // ## xxx ## denotes that the field is not set by the specification
    column.Type = DataColumn.TextType
  }

  // Set link and icon - they are unrelated to the fieldValue
  if (column.Link !== '' && column.Link !== '.' && !column.Link.includes('##')) {
    dataValueField.setLink(column.Link)
  }

  if (column.Icon !== '' && !column.Icon.includes('##')) {
    dataValueField.setIcon(column.Icon)
  }

  if (fieldValue === undefined || fieldValue === 'NA' || fieldValue === '-' || fieldValue === '') {
    if (column.Type === DataColumn.RealType) {
      column.pushData(targetID, column.MissingValue)
    } else if (column.Type === DataColumn.BooleanType) {
      dataValueField.setValue(toBooleanValue(column.MissingValue, column.MissingValue))
      column.pushData(targetID, dataValueField)
    } else {
      dataValueField.setValue(column.MissingValue)
      dataValueField.setText(column.MissingValue)
      column.pushData(targetID, dataValueField)
    }
  } else {
    if (column.Type === DataColumn.TextType) {
      dataValueField.setValue(fieldValue)
      dataValueField.setText(fieldValue)
    }
    if (column.Type === DataColumn.LinkTextType) {
      dataValueField.setValue(fieldValue)
      dataValueField.setText(fieldValue)
    }
    if (column.Type === DataColumn.BooleanType) {
      dataValueField.setValue(toBooleanValue(fieldValue, column.MissingValue))
    }
    if (column.Type === DataColumn.IntType) {
      dataValueField.setValue(Math.round(Number(fieldValue.replaceAll(',', '.'))))
    }

    if (column.Type === DataColumn.LinkType) {
      dataValueField.setValue(fieldValue)
      dataValueField.setText(fieldValue)
    }

    if (column.Type === DataColumn.ScoreType) {
      if (!isNaN(fieldValue.replaceAll(',', '.'))) {
        dataValueField.setValue(Number(fieldValue.replaceAll(',', '.')))
      }
    }
    if (column.Type === DataColumn.PvalueType) {
      if (!isNaN(fieldValue.replaceAll(',', '.'))) {
        dataValueField.setValue(Number(fieldValue.replaceAll(',', '.')))
      }
    }
    if (column.Type === DataColumn.LogFcType) {
      if (!isNaN(fieldValue.replaceAll(',', '.'))) {
        dataValueField.setValue(Number(fieldValue.replaceAll(',', '.')))
      }
    }
    if (column.Type === DataColumn.HpaCategory) {
      dataValueField.setValue(fieldValue)
      dataValueField.setText(fieldValue)
    }
    if (column.Type === DataColumn.NormalizedCount) {
      if (!isNaN(fieldValue.replaceAll(',', '.'))) {
        dataValueField.setValue(Number(fieldValue.replaceAll(',', '.')))
      }
    }
    if (column.Type === DataColumn.LogCPM) {
      if (!isNaN(fieldValue.replaceAll(',', '.'))) {
        dataValueField.setValue(Number(fieldValue.replaceAll(',', '.')))
      }
    }
    if (column.Type === DataColumn.RealType) {
      // dataValueField.setValue(Number(fieldValue.replaceAll(',', '.')))
      if (!isNaN(fieldValue.replaceAll(',', '.'))) {
        column.pushData(targetID, Number(fieldValue.replaceAll(',', '.')))
      } else {
        column.pushData(targetID, column.MissingValue)
      }
      // column.pushData(targetID, dataValueField)
    } else {
      column.pushData(targetID, dataValueField)
    }
  }
}

// function makeAC () {
//   let result = ''
//   const grossList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
//   const grossListAplpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
//   const maxIndex = grossList.length
//   const maxIndexAlpha = grossListAplpha.length
//   result += grossList.charAt(Math.floor(Math.random() * maxIndexAlpha))
//   result += grossList.charAt(Math.floor(Math.random() * maxIndex))
//   result += grossList.charAt(Math.floor(Math.random() * maxIndex))
//   result += grossList.charAt(Math.floor(Math.random() * maxIndex))
//   if (Math.random() < 0.35) {
//     result += grossList.charAt(Math.floor(Math.random() * maxIndex))
//   }
//   return result + '_HUMAN'
// }
