//
//
//
//
//
//
//

import Navigation from '@/components/Navigation'

export default {
  components: { Navigation }
}
