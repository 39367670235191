export const About = () => import('../../components/About.vue' /* webpackChunkName: "components/about" */).then(c => wrapFunctional(c.default || c))
export const BooleanDataField = () => import('../../components/BooleanDataField.vue' /* webpackChunkName: "components/boolean-data-field" */).then(c => wrapFunctional(c.default || c))
export const ColumnSelector = () => import('../../components/ColumnSelector.vue' /* webpackChunkName: "components/column-selector" */).then(c => wrapFunctional(c.default || c))
export const ColumnSelectorItem = () => import('../../components/ColumnSelectorItem.vue' /* webpackChunkName: "components/column-selector-item" */).then(c => wrapFunctional(c.default || c))
export const DatasetFileInfos = () => import('../../components/DatasetFileInfos.js' /* webpackChunkName: "components/dataset-file-infos" */).then(c => wrapFunctional(c.default || c))
export const Downloads = () => import('../../components/Downloads.vue' /* webpackChunkName: "components/downloads" */).then(c => wrapFunctional(c.default || c))
export const DownloadsTable = () => import('../../components/DownloadsTable.vue' /* webpackChunkName: "components/downloads-table" */).then(c => wrapFunctional(c.default || c))
export const FavoriteStar = () => import('../../components/FavoriteStar.vue' /* webpackChunkName: "components/favorite-star" */).then(c => wrapFunctional(c.default || c))
export const FilterColumnSelector = () => import('../../components/FilterColumnSelector.vue' /* webpackChunkName: "components/filter-column-selector" */).then(c => wrapFunctional(c.default || c))
export const FilterColumnSelectorItem = () => import('../../components/FilterColumnSelectorItem.vue' /* webpackChunkName: "components/filter-column-selector-item" */).then(c => wrapFunctional(c.default || c))
export const GeneListFilterModal = () => import('../../components/GeneListFilterModal.vue' /* webpackChunkName: "components/gene-list-filter-modal" */).then(c => wrapFunctional(c.default || c))
export const IntegerDataField = () => import('../../components/IntegerDataField.vue' /* webpackChunkName: "components/integer-data-field" */).then(c => wrapFunctional(c.default || c))
export const LinkDataField = () => import('../../components/LinkDataField.vue' /* webpackChunkName: "components/link-data-field" */).then(c => wrapFunctional(c.default || c))
export const LinkTextDataField = () => import('../../components/LinkTextDataField.vue' /* webpackChunkName: "components/link-text-data-field" */).then(c => wrapFunctional(c.default || c))
export const LoadingGridEmpty = () => import('../../components/LoadingGridEmpty.js' /* webpackChunkName: "components/loading-grid-empty" */).then(c => wrapFunctional(c.default || c))
export const LoginPrompt = () => import('../../components/LoginPrompt.vue' /* webpackChunkName: "components/login-prompt" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const PasswordReset = () => import('../../components/PasswordReset.vue' /* webpackChunkName: "components/password-reset" */).then(c => wrapFunctional(c.default || c))
export const PasswordResetRequest = () => import('../../components/PasswordResetRequest.vue' /* webpackChunkName: "components/password-reset-request" */).then(c => wrapFunctional(c.default || c))
export const PortalFrame = () => import('../../components/PortalFrame.vue' /* webpackChunkName: "components/portal-frame" */).then(c => wrapFunctional(c.default || c))
export const RealDataField = () => import('../../components/RealDataField.vue' /* webpackChunkName: "components/real-data-field" */).then(c => wrapFunctional(c.default || c))
export const ScoreDataField = () => import('../../components/ScoreDataField.vue' /* webpackChunkName: "components/score-data-field" */).then(c => wrapFunctional(c.default || c))
export const TargetFilter = () => import('../../components/TargetFilter.vue' /* webpackChunkName: "components/target-filter" */).then(c => wrapFunctional(c.default || c))
export const TargetList = () => import('../../components/TargetList.vue' /* webpackChunkName: "components/target-list" */).then(c => wrapFunctional(c.default || c))
export const TextDataField = () => import('../../components/TextDataField.vue' /* webpackChunkName: "components/text-data-field" */).then(c => wrapFunctional(c.default || c))
export const LoadingSpinner = () => import('../../components/loading-spinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const FilterBlock = () => import('../../components/filter/FilterBlock.vue' /* webpackChunkName: "components/filter-block" */).then(c => wrapFunctional(c.default || c))
export const FilterCreate = () => import('../../components/filter/FilterCreate.vue' /* webpackChunkName: "components/filter-create" */).then(c => wrapFunctional(c.default || c))
export const FilterEditModal = () => import('../../components/filter/FilterEditModal.vue' /* webpackChunkName: "components/filter-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterList = () => import('../../components/filter/FilterList.vue' /* webpackChunkName: "components/filter-list" */).then(c => wrapFunctional(c.default || c))
export const FilterModal = () => import('../../components/filter/FilterModal.vue' /* webpackChunkName: "components/filter-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterRealDataFilter = () => import('../../components/filter/RealDataFilter.vue' /* webpackChunkName: "components/filter-real-data-filter" */).then(c => wrapFunctional(c.default || c))
export const FilterTextDataFilter = () => import('../../components/filter/TextDataFilter.vue' /* webpackChunkName: "components/filter-text-data-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
