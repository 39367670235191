/**
 * The column Selector Vuex store.
 *
 * Documentation pending :-)
 *
 */

import Vue from 'vue'
import cloneDeep from 'clone-deep'

export const state = () => ({
  selectorColumnDefs: [],
  selectorColumnState: [],
  selectorSearch: '',
  initialColumnState: []
})

export const getters = {
}

export const actions = {
  checkSetInitialColumnState ({ state, commit }, selectorColumnState) {
    if (state.initialColumnState.length === 0) {
      const initialColumnState = cloneDeep(selectorColumnState)
      commit('setInitialColumnState', initialColumnState)
    }
  }
}

export const mutations = {
  setSelectorColumnDefs (state, selectorColumnDefs) {
    state.selectorColumnDefs = selectorColumnDefs
  },
  setColumnDefParent (state, columnDef, parentColumnDef) {
    // Unused ???
    Vue.set(columnDef, 'parent', parentColumnDef)
  },
  setSelectorColumnState (state, selectorColumnState) {
    state.selectorColumnState = selectorColumnState
    // state.selectorColumnState.forEach((columnState) => {
    //   state.selectorColumnStateIndexed[columnState.colId] = columnState
    // })
  },
  setSelectorSearch (state, value) {
    state.selectorSearch = value
  },
  setInitialColumnState (state, initialColumnState) {
    state.initialColumnState = initialColumnState
  },
  hideColumn (state, columnState) {
    // Should move to AgGridColumns
    Vue.set(columnState, 'hide', true)
  },
  showColumn (state, columnState) {
    // Should move to AgGridColumns
    Vue.set(columnState, 'hide', false)
  }
}
