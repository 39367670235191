//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import axios from 'axios'

export default {
  data () {
    return {
      form_email: '',
      requestError: '',
      requestSuccess: ''
    }
  },
  computed: {
    showCloseButton () {
      if (this.requestSuccess) {
        return true
      }
      return false
    }
  },
  methods: {
    requestClick () {
      const data = { email: this.form_email }
      axios.post(this.$config.backendURL + '/reset-password-token', data).then(() => {
        this.requestError = ''
        this.requestSuccess = `An e-mail has been sent to: ${this.form_email}`
      }).catch(() => {
        // console.log('Error!')
        this.requestSuccess = ''
        this.requestError = 'Something has gone wrong, please contact support for assistance.'
      })
    },
    cancelClick () {
      this.$emit('passwordResetRequestCancelled')
    },
    closeClick () {
      this.$emit('passwordResetRequestClosed')
    }

  }
}
